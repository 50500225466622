import { createApiController } from ":shared/utils/api";
import { default as store } from "./store";
import type { TokenPayload, TokenDecoded, TokenTypeKey, TokenType } from "./_model";
import { TOKEN_TYPE } from "./constants";
import { decodeError } from "./api-helpers/decode-errors";
import auth_routes from ":src/routes/auth/routes";

let token_key: TokenTypeKey = undefined;
function redirectToUserRouteBase(props: { jwt: any; clearToken: any }) {
  const { jwt, clearToken } = props;
  // console.log("token Obtained :: ", jwt, " :: ", res);
  if (!token_key) {
    store.actions.alert.pushToast({ type: "error", message: "unknown user type, redirecting!" });
    // store.actions.route.navigate(ROUTE_BASES.auth);
    return;
  } else if (token_key === "filterer") {
    store.actions.alert.pushToast({
      type: "error",
      message: "this phase has already passed!",
    });
    clearToken();
    return;
  }
  let base_route = TOKEN_TYPE?.[token_key]?.base_route;
  if (!base_route) {
    console.warn("base route is not defined for token type ", token_key);
    return;
  }
  store.actions.alert.pushToast({ type: "success", message: `logged in as ${jwt.first_name}` });
  store.actions.route.navigateHref({ base: base_route });
}
export default createApiController({
  baseUrl: import.meta.env.__API,
  triggers: {
    onInitAccessDenied: (access) => {
      if (access.localstorageDenied) {
        store.actions.route.navigateHref({ base: "/auth", path: "/login" });
      }
    },
  },
  interceptors: {
    processRespondsErrors({ err }) {
      const data = err?.response?.data;
      const is_doc_type = typeof data === "string" && data.startsWith("<!");
      let error_message = decodeError(err, "unknown error");
      if (is_doc_type) {
        error_message = err.response.statusText;
      } else {
        error_message = decodeError(err, "unknown error");
      }
      store.actions.alert.pushToast({
        type: "error",
        message: error_message ?? "an error occured",
      });
      return {
        message: error_message,
      };
      // console.log("intercepted response error ,", error_message);
      // console.log("intercepted response error ,", err);
    },
  },
  auth: {
    mode: "localstorage",
    // manulTokenExpiration: { unit: "s", period: 20 }, // use for testing only, haven't tested this in production
    defaults: {
      // invalidToken: "logout",
    },

    endpoints: {
      postToken: {
        url: "/auth/token",
        schema: null as TokenPayload,
        jwt_schema: null as TokenDecoded,
      },
      postTokenRevalidate: { url: "/auth/token/refresh", schema: null as TokenPayload },
      // getTokenRevalidate: { url: "/auth/token/details/" },
      // getTokenRemove: { url: "auth/logout/" },
    },
    triggers: {
      onTokenDecoded({ jwt, res }) {
        // const d = new Date(0);
        // d.setUTCSeconds(jwt.exp);
        // const date = new Date();
        // const diff = d.getTime() - date.getTime();
        // console.log("onTokenDecoded :: ", jwt);
        const id = jwt.user_type;
        // let token_type = undefined;
        token_key = undefined;
        for (const key in TOKEN_TYPE) {
          const value = TOKEN_TYPE[key];
          if (value.id === id) {
            // token_type = value;
            token_key = key as TokenTypeKey;
            break;
          }
        }
        if (!token_key) {
          throw new Error("TOKEN TYPE WITH ID DOESN'T EXIST :: " + id);
        }
      },
      // signin/token refreshed
      onTokenObtained({ jwt, res, internalInvokers }) {
        console.log("token Obtained :: ", jwt, " :: ", res);
        redirectToUserRouteBase({ jwt, clearToken: internalInvokers.clearToken });
      },
      onTokenUpdate: ({ jwt }) => {
        // console.log("token updated :: ", jwt);
      },
      onTokenRemove: ({ jwt, res, err }) => {
        // console.log("token removed :: ", jwt);
        if (jwt || err) {
          store.actions.route.navigateHref({ base: auth_routes.BASE, path: auth_routes.login });
        }
      },
      onUnauthorizedAccess({ jwt, err, defaults }) {
        console.log("on unauthorized access :: ", err);
        return defaults.invalidToken;
      },
      onInvalidatedToken({ state, defaults }) {
        // logout on invalid token upon token remove call
        if (state.auth.endpoints.is_token_revalidate) {
          return "logout";
        }
        return defaults.invalidToken;
      },
      // onOneTimeSessionsActivated: () => {
      //   toast_group$actions.pushToast({
      //     type: "info",
      //     message:
      //       "One Time Session is activated due to disabled cookies. \nYoull stay connected so long you don't reload the page or exit browser!.",
      //   });
      // },
    },
    events: {
      getToken: ({ res }) => res?.data?.access,
      getTokenRefresh: ({ res }) => ({
        refresh: res?.data?.refresh,
      }),
      getTokenRevalidate: ({ res, err }) => {
        if (err) {
          return undefined;
        }
        // console.log("token is revalidated :: ", res, " :: ", err);
        return res;
      },
    },
    customEvents: ({ getJWT, getRes, internalInvokers }) => ({
      hasToken: () => {
        return internalInvokers.tokenExist();
      },
      getDecodedToken: () => {
        return getJWT();
      },
      getTokenKey() {
        if (!token_key) return undefined;
        return token_key;
      },
      getTokenObject<T extends TokenTypeKey>(key: T): TokenType[T] {
        return TOKEN_TYPE[key];
      },
      redirectToUserRouteBase: () => {
        redirectToUserRouteBase({ jwt: getJWT(), clearToken: internalInvokers.clearToken });
      },
    }),
  },
});
